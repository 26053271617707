import React, { FC, useEffect, useState } from "react";
import { ROOT_PATHS } from "../routes/paths";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../contexts/BaseConfigContext";
import { icons } from "../assets/icons";
import { Icon } from "../components/UI/Icon";
import { ContactItem, FooterSection } from "../components";
import { SOCIAL_LINKS } from "../constants/footer";
import { Link, useNavigate } from "react-router-dom";
import { locale } from "../helpers/language";


const AddressAndContact = () => (
  <div className="flex flex-col sm:flex-row sm:justify-between gap-6 mt-8">
    <div className="flex flex-col sm:flex-row gap-6">
      <ContactItem
        Icon={() => <Icon image={icons.Location} alt="twitter" size={25} />}
        text="г.Бишкек, пр. Чуй, 36а"
        bgColor="black"
        href="https://www.google.com/maps?q=г.Бишкек,+пр.+Чуй,+36а"
      />
      <ContactItem
        Icon={() => <Icon image={icons.Gmail} alt="twitter" size={25} />}
        text="office@ifcenter.kg"
        bgColor="black"
        href="mailto:office@ifcenter.kg"
      />
    </div>
    <div className="flex gap-4">
      {SOCIAL_LINKS.map(({ href, alt, icon }) => (
        <a key={href} href={href} target="_blank" rel="noopener noreferrer">
          <Icon image={icon} alt={alt} size={25} />
        </a>
      ))}
    </div>
  </div>
);

const MainFooter = () => {
  const { t } = useTranslation();
  const { state: { productItems }, actions: { fetchProductItemsData } } = useAppContext();
  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    fetchProductItemsData()
  }, [])




  const clientItems = [
    {
      links: productItems.filter((product) => product.isActive && product.product_type !== "business")?.map((item) => {
        return (
          {
            href: item?.product_id,
            text: item.title,
          }
        )
      }
      ) || [],
    },
  ];

  const businessItems = [
    {
      links: productItems.filter((product) => product.isActive && product.product_type !== "client")?.map((item) => {
        return (
          {
            href: item?.product_id,
            text: item.title,
          }
        )
      }
      ) || [],
    },
  ];

  const navItems = [
    { href: ROOT_PATHS.about, text: t("footer.aboutCenter") },
    { href: ROOT_PATHS.partner, text: t("footer.partners") },
    { href: ROOT_PATHS.shariaCouncil, text: t("footer.shariaCouncilMembers") },
    { href: ROOT_PATHS.news, text: t("footer.news") },
    { href: ROOT_PATHS.vacancies, text: t("footer.vacancies") },
  ];

  return (
    <div>
      <div className="border-header-border-gray border-t-2 dark:bg-dark-mode-card-bg dark:border-[#1F222A]">
        <div className="max-w-7xl px-4 py-4 mx-auto">
          <div className="flex gap-6 ">

            <a href="https://wa.me/996554006111" target="_blank" rel="noopener noreferrer">
              <div className="flex items-center gap-2">
                <Icon image={icons.WhatsAppIcon} alt="whatsapp" size={30} />
                <span className={`text-black font-semibold text-lg  sm:text-xl dark:text-white `}>+996 554 006 111</span>
              </div>
            </a>
            <a href="tel:6111" target="_blank" rel="noopener noreferrer">
              <div className="flex items-center gap-2">
                <Icon image={icons.PhoneIcon} alt="phone" size={30} />
                <span className={`text-black font-semibold text-lg  sm:text-xl dark:text-white `}>6111</span>
              </div>
            </a>

          </div>
        </div>
      </div>

      <div className="bg-dark-paper dark:bg-dark-mode-bg">
        <div className="max-w-7xl px-4 mx-auto">
          <div className="py-8">
            <ul className="flex flex-wrap gap-4 sm:gap-8 mb-8">
              {navItems.map((link, index) => (
                <li
                  key={index}
                  className="text-lg font-[500] text-white"
                >
                  {link.href ? (
                    <Link to={link.href}>{link.text}</Link>
                  ) : (
                    link.text
                  )}
                </li>
              ))}
            </ul>
            <hr className="mb-4 h-0.5 border-t-0 bg-header-border-gray dark:bg-header-border-gray" />

            <div className="w-full grid grid-cols-1 md:grid-cols-2 ml-auto">
              <FooterSection
                title={t("footer.forClients")}
                items={clientItems}
              />
              <FooterSection
                title={t("footer.forBusiness")}
                items={businessItems}
              />
            </div>

            <AddressAndContact />

            <div className="text-sm text-[#99A1B1] mt-4">
              ©{currentYear} ОАО &quot;Бакай Банк&quot;. Все права защищены. Лицензия НБКР № 043
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
