import { IconProps } from "../../types";


export const Icon = ({ image, alt, className, size, color }: IconProps) => {
    const styles = typeof size === "number"
        ? { width: `${size}px`, height: `${size}px` }
        : size
            ? { width: `${size.width}px`, height: `${size.height}px` }
            : {};

    if (image.endsWith(".svg")) {
        return (
            <img
                src={image}
                alt={alt}
                className={className}
                style={{
                    ...styles,
                    color: color || 'currentColor',
                }}
            />
        );
    }

    return <img src={image} alt={alt} className={className} style={styles} />;
};
