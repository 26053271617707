import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import "leaflet.markercluster";
import { FilBranch } from "../types/filialy";
import {icons} from "../assets/icons";




interface Cluster {
  getChildCount: () => number;
}

export const MarkerCluster = ({ locations }: { locations: FilBranch[] }) => {
  const map = useMap();

  useEffect(() => {
    const defaultCenter = L.latLng(41.2044, 74.7661);
    const defaultZoom = 6;

    map.setView(defaultCenter, defaultZoom);
    const markers = (L as any).markerClusterGroup({
      maxClusterRadius: 50,
      iconCreateFunction: (cluster: Cluster) => {
        const count = cluster.getChildCount();
        let clusterClass =
          "bg-green-500 w-12 h-12 rounded-full flex items-center justify-center";

        if (count > 10) {
          clusterClass =
            "bg-red-500 w-12 h-12 rounded-full flex items-center justify-center";
        } else if (count > 5) {
          clusterClass =
            "bg-yellow-500 w-12 h-12 rounded-full flex items-center justify-center";
        }

        return L.divIcon({
          html: `<div class="cluster-icon ${clusterClass} style="z-index:-1000">${count}</div>`,
          className:
            "custom-cluster text-white font-bold shadow-lg hover:shadow-xl transition duration-300 ease-in-out",
          iconSize: L.point(50, 50),
        });
      },
    });

    locations.forEach((location: any) => {
      if (location?.lat && location?.lng) {
        const marker = L.marker([location.lat, location.lng], {
          icon: customIcon,
        }).bindPopup(
          `<div style=" padding: 12px; color: #333; font-family: Arial, sans-serif; ">
                  <h2 style="font-size: 18px; margin: 0 0 8px; color: #005CAA;">${location.address}</h2>
               </div>`
        );
        markers.addLayer(marker);
      }
    });

    map.addLayer(markers);

    return () => {
      map.removeLayer(markers);
    };
  }, [locations, map]);

  return null;
};

const customIcon = new L.Icon({
  iconUrl: icons.IfcIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
