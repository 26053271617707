import { renderContentBlock } from "./editorText";

export const parseText = (text: any, index?: any) => {

    if (!text) {
        return { error: true, message: `` };
    }

    let content;

    try {
        content = JSON.parse(text);
    } catch (error) {
        return { error: true, message: text };
    }

    return { error: false, content };
};


export function truncateText(text: string, maxLength: number) {
    return text.length > maxLength ? `${text.slice(0, maxLength)}` : text;
}


export const renderText = (text: any, limit: number = 40): string => {
    try {
        const content = text.blocks.slice(0, 1).map(renderContentBlock)[0].props.children;
        if (typeof content === "string") {
            return truncateText(content, limit);
        } else if (content.props?.children) {
            return truncateText(content.props.children, limit);
        } else {
            return "";
        }
    } catch (error) {
        return "";
    }
};