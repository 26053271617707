import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface CustomLinkProps {
  children: ReactNode;
  className?: string;
  link?: string;
  onClick?: () => void;
}

export default function CustomLink({
  children,
  className = "",
  link = "/",
  onClick,
}: CustomLinkProps) {
  return (
    <Link
      to={link}
      className={`py-[14px] px-[24px] text-[18px] font-[700] rounded-[32px] ${className}`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
