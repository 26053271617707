import React from 'react';
import { ReactComponent as SliderNextArrow } from "../../../assets/icons/controllers/slider_controller_next.svg";

interface SampleNextArrowProps {
    classes?: string,
    style?: React.CSSProperties;
    onClick?: () => void;
    fillColor?: string;
    noBackground?: boolean;
}

export const SampleNextArrow: React.FC<SampleNextArrowProps> = ({
    classes = '',
    style = {},
    onClick,
    fillColor = '#FFFFFF',
    noBackground = false
}) => {
    return (
        <div
            className={classes}
            style={{
                ...style,
                breakBefore: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: noBackground ? "transparent" : "#00000",
            }}
            onClick={onClick}
        >
            <SliderNextArrow fill={fillColor} />
        </div>
    );
}
