import { useEffect, useState } from 'react';
import { Icon } from './UI/Icon';
import { icons } from '../assets/icons';

const ThemeToggle = () => {
    const [theme, setTheme] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            return savedTheme;
        }
        return window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light';
    });

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    return (
        <div
            onClick={toggleTheme}
            className={`relative flex items-center w-14 h-7 bg-gray-300 dark:bg-black rounded-full cursor-pointer transition-colors duration-300 p-1`}
        >
            <div
                className={`w-5 h-5 bg-white rounded-full shadow-md flex items-center justify-center transform transition-transform duration-300 dark:bg-dark-mode-card-bg ${theme === 'dark' ? 'translate-x-7' : 'translate-x-0'
                    }`}
            >
                {theme === 'light' ? (
                    <Icon image={icons.DarkModeMoon} alt='moon' size={30} />
                ) : (
                    <Icon image={icons.DarkModeSun} alt='sun' size={30} />
                )}
            </div>
        </div>
    );
};

export default ThemeToggle;
