import { useTranslation } from "react-i18next";
import { useAppContext } from "../contexts/BaseConfigContext";
import { useEffect, useState } from "react";
import TabButton from "./UI/TabButton";
import TabContent from "./tabs/TabContent";
import FilialyContent from "./FilialyContent";

export default function FilialyIFS({ title }: { title?: string }) {
  const { t } = useTranslation();
  const { state: { contacts }, actions: { fetchContactsData } } = useAppContext();
  useEffect(() => {
    fetchContactsData()
  }, [])
  const [activeTab, setActiveTab] = useState(1);

  const filBranches = contacts?.[0]?.branches;
  const filFinancing = contacts?.[0]?.financing_points;
  const handleTabClick = (tabIndex: number) => setActiveTab(tabIndex);

  return (
    <>
      <h1 className="text-[40px] text-center font-[700] dark:text-white max-md:text-[30px]">
        {title && title}
      </h1>
      <div>
        <div>
          <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row max-w-7xl px-4 mx-auto">
            <TabButton
              label={t("filialyIFS.branches")}
              tabIndex={1}
              activeTab={activeTab}
              onClick={() => handleTabClick(1)}
            />
            <TabButton
              label={t("filialyIFS.fundingPoints")}
              tabIndex={2}
              activeTab={activeTab}
              onClick={() => handleTabClick(2)}
            />
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 max-w-7xl mx-auto px-4">
            <div className="py-5 flex-auto">
              <TabContent isVisible={activeTab === 1}>
                {activeTab === 1 && <FilialyContent branches={filBranches} />}
              </TabContent>
              <TabContent isVisible={activeTab === 2}>
                {activeTab === 2 && <FilialyContent branches={filFinancing} />}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
