import parse from "html-react-parser";

export const renderContentBlock = (block: any) => {
  const { id, type, data } = block;

  switch (type) {
    case "header":
      return <h2 key={id} className="text-[20px] font-[600] ">{parse(data.text)}</h2>;
    case "paragraph":
      return <p key={id}>{parse(data.text)}</p>;
    case "list":
      return (
        <ol key={id}>
          {data.items.map((item: any, index: any) => (
            <li key={index}>{parse(item.content)}</li>
          ))}
        </ol>
      );
    default:
      return JSON.stringify(data);
  }
};