export const ROOT_PATHS = {
   root: "/",
   products: "products",
   islamCards: "islam-cards",
   about: "about",
   partner: "partner",
   shariaCouncil: "sharia-council",
   financialProfitability: "financial-profitability",
   vacancies: "vacancies",
   news: "news",
   filialy: "filialy",
   profitNorm: "profit-norm",
   newsDetail: "news/:newId",
   vacanciesDetail: "vacancies/:vacancyId",
   productDetails: "products/:productId"
}
