import React, { createContext, useContext, useState, ReactNode } from "react";

interface SnackbarContextProps {
    showSnackbar: (message: string, type?: "success" | "error") => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);


export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState<"success" | "error">("success");

    const showSnackbar = (message: string, type: "success" | "error" = "success") => {
        setSnackbarMessage(message);
        setSnackbarType(type);
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000);
    };

    const snackbarStyles = {
        success: {
            container:
                "fixed top-4 right-4 flex items-center w-full max-w-xs p-4 mb-4 text-white bg-green-700 rounded-lg shadow-xl  z-50 transform transition-all opacity-0 translate-y-4",
            icon: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200",
            svgPath: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z",
        },
        error: {
            container:
                "fixed top-4 right-4 flex items-center w-full max-w-xs p-4 mb-4 text-white bg-red-700 rounded-lg shadow-xl  z-50 transform transition-all opacity-0 translate-y-4",
            icon: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200",
            svgPath: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z",
        },
    };

    const typeStyles = snackbarStyles[snackbarType];

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {isSnackbarOpen && (
                <div
                    className={`${typeStyles.container} z-[5000] opacity-100 translate-y-0`}
                    role="alert"
                >
                    <div className={typeStyles.icon}>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d={typeStyles.svgPath} />
                        </svg>
                        <span className="sr-only">{snackbarType} icon</span>
                    </div>
                    <div className="ms-3 text-sm font-normal">{snackbarMessage}</div>
                    <button
                        type="button"
                        className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
                        onClick={() => setSnackbarOpen(false)}
                        aria-label="Close"
                    >
                        <span className="sr-only">Close</span>
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                    </button>
                </div>
            )}
        </SnackbarContext.Provider>
    );
};




export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};
