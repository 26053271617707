import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import i18n from "../i18/i18n";
import { useSnackbar } from "../contexts/sneckbar";
import { ROOT_PATHS } from "../routes/paths";
import CustomButton from "../components/UI/CustomButtom";
import SelectSmall from "../components/UI/Select";
import Input from "../components/UI/Input";
import ReactInputMask from "react-input-mask";
import { useAppContext } from "../contexts/BaseConfigContext";
import { Services } from "../api/services";
import { icons } from "../assets/icons";
import { Icon } from "../components/UI/Icon";



const Drawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({ fio: "", phone: "", email: "", checkbox: "" }); //
  const { state: { product }, actions: { switchLanguage } } = useAppContext();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [formData, setFormData] = useState({ fio: "", phone: "", product: "" });
  const [loading, setLoading] = useState(false)
  const { lang } = useParams();

  const [isChecked, setIsChecked] = useState(false)

  const [num1, setNum1] = useState<number>(0);
  const [num2, setNum2] = useState<number>(0);
  const [captchaAnswer, setCaptchaAnswer] = useState<string>('');
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const generateCaptcha = () => {
    const number1 = Math.floor(Math.random() * 100);
    const number2 = Math.floor(Math.random() * 10) + 1;
    setNum1(number1);
    setNum2(number2);
  };

  useEffect(() => {
    generateCaptcha();
  }, [openModal]);

  const validateCaptcha = (input: string) => {
    const correctAnswer = num1 + num2;
    setCaptchaAnswer(input);
    setIsCaptchaValid(parseInt(input) === correctAnswer);
  };


  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "phone" && !value.startsWith("+996")) {
      value = "+996";
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = { fio: "", phone: "", email: "", checkbox: "" };

    if (!formData.fio.trim()) {
      newErrors.fio = t('forms.fioError');

    }
    if (!formData.phone.trim()) {
      newErrors.phone = t("forms.phoneError");
    } else if (!/^\+996\s\d{3}\-\d{3}-\d{3}$/.test(formData.phone)) {
      newErrors.phone = t("forms.phoneTest");
    }

    if (!isChecked) {
      newErrors.checkbox = "Вы должны принять условия политики конфиденциальности."
    }
    setErrors(newErrors);
    return !newErrors.fio && !newErrors.phone;
  };


  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  };



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true)
    try {
      const formDataWithProduct = {
        ...formData,
        product: formData.product || product?.[0].title,
      };
      await Services.financForm(formDataWithProduct);
      showSnackbar(t("forms.snackbarSucces"), "success");

      setOpenModal(false);
      setFormData({ fio: "", phone: "", product: "" });
      setIsChecked(false)
    } catch (error) {
      console.error("Form submission error:", error);
      showSnackbar(t("forms.snackbarError"), "error");
      setOpenModal(false);

    } finally {
      setLoading(false)
    }
  };


  const handleProductSelect = (selectedProduct: { id: number | string; title: string }) => {
    setFormData((prevState) => ({
      ...prevState,
      product: selectedProduct.title,
    }));
  };

  const handleLanguageChange = (newLanguage: string) => {
    if (newLanguage !== lang) {
      const newPath = `/${newLanguage}${window.location.pathname.slice(3)}`;
      navigate(newPath);
      window.location.reload()
    }
  };

  useEffect(() => {
    handleLanguageChange(lang!)

    if (lang) {
      i18n.changeLanguage(lang);
      switchLanguage(lang);
    }
  }, [lang]);


  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };


  const toggleModal = () => {
    setOpenModal(!openModal);
    setFormData({ fio: "", phone: "", product: "" });
    setIsChecked(false)
    setCaptchaAnswer("")
    setErrors({ fio: "", phone: "", email: "", checkbox: "" });

  };

  const DrawerLinks = [
    { path: "", label: "Bakai Bank" },
    { path: ROOT_PATHS.financialProfitability, label: t("header.financialReport") },
    { path: ROOT_PATHS.profitNorm, label: t("header.profitNorm") },
    { path: ROOT_PATHS.vacancies, label: t("header.vacancies"), },
    { path: ROOT_PATHS.products, label: t("header.products"), hasBorder: true },
    { path: ROOT_PATHS.partner, label: t("header.partners"), hasBorder: true },
    {
      path: ROOT_PATHS.shariaCouncil,
      label: t("header.shariaCouncil"),
      hasBorder: true,
    },
    { path: ROOT_PATHS.news, label: t("header.news"), hasBorder: true },
    { path: ROOT_PATHS.about, label: t("header.aboutUs"), },
  ];

  return (
    <div>
      <button onClick={toggleDrawer}>
        <Icon image={icons.Burger} alt="Burger" size={30} />
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300"
          onClick={toggleDrawer}
        />
      )}

      <div
        className={`fixed top-0 right-0 w-full bg-white z-[2000] shadow-lg h-full transform transition-transform duration-300 dark:bg-dark-mode-bg ${isOpen ? "translate-x-0 z-50" : "translate-x-full"
          }`}
      >
        <div className="p-4 h-full">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-baseline">
              <Link to={"https://www.bakai.kg/ru/"} target="_blank" rel="noopener noreferrer">
                <Icon image={icons.BakaiLogo} alt="BakaiLogo" />

              </Link>
              <div className="h-[60%] w-0.5 bg-neutral-100 dark:bg-white/10" />

              <Link to={'/'} onClick={toggleDrawer}>
                <Icon image={icons.LogoIcon} alt="LogoIcon" />

              </Link>

            </div>
            <button onClick={toggleDrawer} className="text-gray-500">
              <Icon image={icons.MenuClose} alt="MenuClose" />
            </button>
          </div>

          <div className="mt-4 space-y-4 h-[90%] flex flex-col">
            <div className="flex flex-wrap items-center">
              {DrawerLinks.slice(0, 4).map((link) => (
                <Link
                  key={link.label}
                  to={link.path}
                  onClick={toggleDrawer}
                  className="text-gray-700 py-1 pr-8 font-[500] dark:text-white"
                >
                  {link.label}
                </Link>
              ))}
            </div>

            <ul className="space-y-2 flex-grow">
              {DrawerLinks.slice(4).map((link) => (
                <li
                  key={link.label}
                  className={link.hasBorder ? "border-b-2 pb-3" : ""}
                >
                  <Link
                    to={link.path}
                    onClick={toggleDrawer}
                    className="font-medium dark:text-white"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="flex space-x-2 mt-4 justify-center bg-gray-100 p-2 rounded-xl dark:bg-dark-mode-card-bg">
              {["ru", "ky"].map((lang: any) => (
                <button
                  key={lang}
                  onClick={() => handleLanguageChange(lang)}
                  className={`px-4 py-2 rounded-full ${selectedLanguage === lang ? "bg-[#01554F] text-white" : "bg-gray-200 dark:bg-dark-mode-bg dark:text-white"}`}
                >
                  {lang === "ru" ? "РУ" : "КЫР"}
                </button>
              ))}
            </div>


            <CustomButton
              onClick={toggleModal}
              className="mt-4 w-full py-3 bg-[#01554F] text-white rounded-full"
            >
              {t("header.financingButtonMobail")}
            </CustomButton>

            <div className="flex items-center justify-center space-x-4 mt-4">
              <Link
                to="tel:611"
                target="_blank"
                className="text-gray-600 flex items-center gap-2 text-lg font-[400] dark:text-white"
              >
                <Icon image={icons.PhoneIcon} alt="Phone" /> 6111
              </Link>
              <Link
                to="https://wa.me/996554006111"
                target="_blank"
                className="flex items-center  text-gray-600 dark:text-white"
              >
                <Icon image={icons.WhatsAppIcon} alt="WhatsAppIcon" size={30} />
                <span className="ml-1 text-lg font-[400]">
                  +996 554 006 111
                </span>
              </Link>
              <Link
                onClick={toggleDrawer}
                to={ROOT_PATHS.filialy}
                className="text-gray-600 text-lg font-[400] dark:text-white"
              >
                {t("header.branches")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <div className="fixed inset-0 z-[3000] bg-white flex items-center justify-center p-6 dark:bg-dark-mode-bg">
          <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg relative animate-slide-down dark:bg-dark-mode-card-bg">

            <div className="space-y-4 mt-10" >
              <div className="flex flex-col gap-6 justify-start">
                <SelectSmall
                  label="Продукты"
                  product={product}
                  onSelect={handleProductSelect}
                />
                <Input
                  error={errors.fio}
                  placeholder="Введите имя"
                  value={formData.fio}
                  onChange={handleInputChange}
                  type="text"
                  name="fio"
                />

                <ReactInputMask
                  mask="+996 999-999-999"
                  maskChar={null}
                  value={formData.phone || "+996"}
                  onChange={handleInputChange}
                  onFocus={() => {
                    if (!formData.phone) {
                      setFormData((prevState) => ({ ...prevState, phone: "+996" }));
                    }
                  }}
                >
                  {() => (

                    <Input
                      error={errors.phone}
                      name="phone"
                      placeholder="Номер телефона"
                      aria-describedby="phone-error-text"
                    />
                  )}
                </ReactInputMask>

              </div>

              <label className="flex flex-col items-start">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="ml-3 text-sm text-gray-700 dark:text-white">
                    Согласен с условиями политики конфиденциальности.
                  </span>
                </div>
                {errors.checkbox && (
                  <p className="text-red-500 text-sm mt-1">{errors.checkbox}</p>
                )}
              </label>
              <Input
                label={`Сколько будет ${num1} + ${num2}?`}
                placeholder="Введите ответ"
                value={captchaAnswer}
                onChange={(event) => validateCaptcha(event.target.value)}
              />

              <CustomButton
                onClick={handleSubmit}
                className="w-full py-3 bg-[#01554F] text-white rounded-full"
                disabled={!isCaptchaValid}

              >
                {loading ? t("forms.btnMobailePanding") : t("forms.btnModal")}
              </CustomButton>
            </div>
            <div className="text-center mt-4">
              <p className="text-gray-500 font-[500] dark:text-white">611 для звонков по КР</p>
              <Link
                to="https://wa.me/996554006111"
                className="text-[#01554F] font-[500]"
              >
                +996 554 006 011 WhatsApp
              </Link>
            </div>
            <div className="">
              <h2 className="absolute top-1 left-4 text-[20px] font-semibold dark:text-white ">
                {t('forms.titleMobail')}
              </h2>
              <button
                onClick={toggleModal}
                className="absolute top-1 right-4 text-gray-500"
              >
                <Icon image={icons.MenuClose} alt="MenuClose" />
              </button>
            </div>

          </div>
        </div>
      )}


    </div>
  );
};

export default Drawer;
