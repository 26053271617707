import Card1 from "../assets/images/qr.webp"
import Card2 from "../assets/images/card1.webp"
import Card3 from "../assets/images/card2.webp"
import Card4 from "../assets/images/card3.webp"
import Card5 from "../assets/images/card4.webp"





export const carta = [
    {
        nums: 1,
        title: "Скачайте приложение BakAi",
        image: Card1
    },
    {
        nums: 2,
        title: "Перейдите в раздел «Карты» в главном меню и нажмите «+»",
        image: Card2
    },
    {
        nums: 3,
        title: "Выберите вид заявки",
        image: Card3
    },
    {
        nums: 4,
        title: "Выберите вид карты (Visa/Элкарт)",
        image: Card4
    }, {
        nums: 5,
        title: "Ознакомьтесь с тарифами и условиями и оформите карту с доставкой",
        image: Card5
    },
]

