import { useEffect, useState } from "react";
import { useSnackbar } from "../../contexts/sneckbar";
import Input from "../UI/Input";
import CustomButton from "../UI/CustomButtom";
import { Link } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import SelectSmall from "../UI/Select";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../contexts/BaseConfigContext";
import { Services } from "../../api/services";
import ReCAPTCHA from "react-google-recaptcha";

export const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const FinancingForm = ({ open, setOpen }: any) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({ fio: "", phone: "", checkbox: "", captcha: "" });
    const { state: { product } } = useAppContext();
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const [formData, setFormData] = useState({
        fio: "",
        phone: "",
        product: "",
        checkbox: "",
        captcha: ""
    });
    const [loading, setLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const { showSnackbar } = useSnackbar();

    const onChangeCaptcha = (v: any) => {
        setCaptchaResponse(v);

        if (v) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                captcha: "",
            }));
        }
    };


    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (name === "phone" && !value.startsWith("+996")) {
            value = "+996";
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const validateForm = () => {
        const newErrors = { fio: "", phone: "", email: "", checkbox: "", captcha: "" };

        if (!formData.fio.trim()) {
            newErrors.fio = t('forms.fioError');

        }
        if (!formData.phone.trim()) {
            newErrors.phone = t("forms.phoneError");
        } else if (!/^\+996\s\d{3}\-\d{3}-\d{3}$/.test(formData.phone)) {
            newErrors.phone = t("forms.phoneTest");
        }
        if (!isChecked) {
            newErrors.checkbox = "Необходимо принять условие."
        }

        if (!captchaResponse) {
            newErrors.captcha = "Пожалуйста, подтвердите, что вы не робот.";
        }

        setErrors(newErrors);
        return !newErrors.fio && !newErrors.phone;
    };


    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
    };




    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        setLoading(true)
        try {
            const formDataWithProduct = {
                ...formData,
                product: formData.product || product?.find((item: any) => item)?.title || "",
            };
            await Services.financForm(formDataWithProduct);
            showSnackbar(t("forms.snackbarSucces"), "success");

            setOpen(false);
            setFormData({ fio: "", phone: "", product: "", checkbox: "" ,captcha: ""});

        } catch (error) {
            console.error("Form submission error:", "error");
            showSnackbar(t("forms.snackbarError"), "error");
            setOpen(false);

        } finally {
            setLoading(false)
        }
    };

    const handleCloseModal = () => {
        const newErrors = { fio: "", phone: "", email: "", checkbox: "", captcha: "" };
        setOpen(false)
        setFormData({ fio: "", phone: "", product: "", checkbox: "" ,captcha: ""});

        setErrors(newErrors)
        setIsChecked(false)
    }




    const handleProductSelect = (selectedProduct: { id: number | string; title: string }) => {
        setFormData((prevState) => ({
            ...prevState,
            product: selectedProduct.title,
        }));
    };

    return (
        <div className="w-full px-4  grid gap-6 bg-transparent">
            <SelectSmall
                label="Продукты"
                product={product}
                onSelect={handleProductSelect}
            />
            <Input
                error={errors.fio}
                placeholder="Введите имя"
                value={formData.fio}
                onChange={handleInputChange}
                type="text"
                name="fio"
            />

            <ReactInputMask
                mask="+996 999-999-999"
                maskChar={null}
                value={formData.phone || "+996"}
                onChange={handleInputChange}
                onFocus={() => {
                    if (!formData.phone) {
                        setFormData((prevState) => ({ ...prevState, phone: "+996" }));
                    }
                }}
            >
                {() => (

                    <Input
                        error={errors.phone}
                        name="phone"
                        placeholder="Номер телефона"
                        aria-describedby="phone-error-text"
                    />
                )}
            </ReactInputMask>


            <label className="flex flex-col items-start">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">
                        Согласен с условиями политики конфиденциальности.
                    </span>
                </div>
                {errors.checkbox && (
                    <p className="text-red-500 text-sm mt-1">{errors.checkbox}</p>
                )}
            </label>


            <ReCAPTCHA
                sitekey={`${captchaKey}`}
                onChange={onChangeCaptcha}
            />
            {errors.captcha && (
                <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>
            )}

            <CustomButton
                onClick={handleSubmit}
                className="w-full py-3 bg-[#01554F] text-white rounded-full"
                disabled={loading || !captchaResponse}
            >
                {loading ? t("forms.btnMobailePanding") : t("forms.btnModal")}
            </CustomButton>


            <hr />
            <div className="flex flex-col gap-1">
                <p className="text-gray-500 font-[500] dark:text-gray-300">611 для звонков по КР</p>
                <Link
                    to="https://wa.me/996554006111"
                    className="text-[#01554F] font-[500]"
                >
                    +996 554 006 011 WhatsApp
                </Link>
            </div>
        </div>
    )
}