import React from "react";
import './App.css';
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import AppProvider from "./contexts/BaseConfigContext";
import { SnackbarProvider } from "./contexts/sneckbar";
import ScrollToTop from "./components/ScrollToTop";

export default function App() {
    return (
        <SnackbarProvider>
            <AppProvider>
                <ScrollToTop />
                <RouterProvider router={router} />
            </AppProvider>
        </SnackbarProvider>
    );
}
