import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";

const IFS_PUBLIC_API_ENDPOINT = process.env.REACT_APP_BASE_API;

const language = window.location.pathname.split("/")[1] || "ru";


const API_HEADERS = {
    'Content-Type': 'application/json',
    'Accept-Language': language
};

const api = axios.create({
    baseURL: IFS_PUBLIC_API_ENDPOINT,
    headers: API_HEADERS
});

api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

export const BaseApiWrapper = {
    get: (url: string, config?: AxiosRequestConfig) => api.get(url, config).then(handleResponse),
    post: (url: string, data?: any, config?: AxiosRequestConfig) => api.post(url, data, config).then(handleResponse),
    put: (url: string, data?: any, config?: AxiosRequestConfig) => api.put(url, data, config).then(handleResponse),
    patch: (url: string, data?: any, config?: AxiosRequestConfig) => api.patch(url, data, config).then(handleResponse),
    delete: (url: string, config?: AxiosRequestConfig) => api.delete(url, config).then(handleResponse),
    uploadFile: (url: string, data?: any, config?: AxiosRequestConfig) => {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        return api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data', ...config?.headers } }).then(handleResponse);
    },
};


function handleResponse(response: AxiosResponse): any {
    return response?.data ?? [];
}
