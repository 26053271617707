import React, { useEffect, useState } from "react";
import i18n from "../i18/i18n";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../contexts/BaseConfigContext";
import { icons } from "../assets/icons";
import { Icon } from "../components/UI/Icon";
import { useNavigate, useParams } from "react-router-dom";

const LanguageSwitcher = () => {
    const { t } = useTranslation();
    const { lang } = useParams();
    const { actions: { switchLanguage } } = useAppContext();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);



    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleLanguageChange = (newLanguage: string) => {
        if (newLanguage !== lang) {
            const newPath = `/${newLanguage}${window.location.pathname.slice(3)}`;
            navigate(newPath);
            window.location.reload()
        }
        setDropdownOpen(false);
    };

    useEffect(() => {
        handleLanguageChange(lang!)

        if (lang) {
            i18n.changeLanguage(lang);
            switchLanguage(lang);
        }
    }, [lang]);

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="flex items-center gap-x-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50 dark:bg-dark-mode-card-bg dark:text-white"
                aria-haspopup="true"
            >
                <Icon
                    image={lang === "ru" ? icons.LanguageRu : icons.LanguageKg}
                    className="w-6 h-4 mr-1"
                    alt={lang === "ru" ? "Russian flag" : "Kyrgyz flag"}
                />
                {lang === "ru" ? t("header.languageRus") : t("header.languageKyr")}
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path
                        fillRule="evenodd"
                        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {dropdownOpen && (
                <div className="absolute right-0 z-10 mt-2 w-40 rounded-md bg-white shadow-lg ring-1 ring-black/5 dark:bg-dark-mode-card-bg">
                    <button
                        onClick={() => handleLanguageChange("ru")}
                        className="flex items-center gap-3 w-full px-4 py-2 text-sm font-semibold hover:bg-gray-100 dark:hover:bg-[#262931]"
                    >
                        <Icon image={icons.LanguageRu} alt="ru" size={20} />
                        <p className={"dark:text-white"}>{t("header.languageRus")}</p>
                    </button>
                    <button
                        onClick={() => handleLanguageChange("ky")}
                        className="flex items-center gap-2 w-full px-4 py-2 text-sm font-semibold hover:bg-gray-100 dark:hover:bg-[#262931]"
                    >
                        <Icon image={icons.LanguageKg} alt="ky" size={20} />
                        <p className={"dark:text-white"}>{t("header.languageKyr")}</p>
                    </button>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;
