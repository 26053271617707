import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { icons } from "../../assets/icons";
import { Icon } from "./Icon";
import { locale } from "../../helpers/language";

interface BreadcrumbInterFace {
    breadcrum1?: string;
    breadcrum2?: string | undefined;
    link1?: string | undefined;
    link2?: string | undefined;
    breadcrum3?: string;
    color?: string;
    icon?: string;
}

const Breadcrumb = ({
    breadcrum1 = "Breadcrumb.home",
    breadcrum2,
    breadcrum3 = "",
    link1 = "/",
    link2 = "",
    color,
    icon = icons.BreadcrumbIcon
}: BreadcrumbInterFace) => {
    const { t } = useTranslation();
    const defaultTextColor = "text-[#6B7280] ";
    const textColor = color ? `text-[${color}]` : defaultTextColor;
    const linkColor = color ? "text-white" : "hover:text-dark-blue dark:hover:text-white";

    return (
        <div
            className={`font-[500] text-[16px] items-center sm:flex sm:items-center sm: flex flex-wrap ${color ? `bg-[${color}]` : ""}`}>
            <Link to={link1} className={`${textColor} text-sm ${linkColor}`}>
                {t(breadcrum1)}
            </Link>
            <Icon image={icon} alt="breadcrumb" size={20} color={"red"} />

            <Link
                to={`/${locale}${link2}`}
                className={`${breadcrum3 ? textColor : "text-black dark:text-white"} text-sm ${linkColor}`}
            >
                {breadcrum2}
            </Link>

            {breadcrum3 && (
                <>
                    <Icon image={icon} alt="breadcrumb" size={20} />
                    <span className={`${color ? "text-white" : "text-dark-blue dark:text-white"} text-sm`}>
                        {breadcrum3}
                    </span>
                </>
            )}
        </div>
    );
};

export default Breadcrumb;
