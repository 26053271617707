import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
    useCallback,
} from "react";
import { Services } from "../api/services";
import i18n from "../i18/i18n";


interface AppProviderProps {
    children: ReactNode;
}


interface AppState {
    product: any[];
    banner: any[];
    news: any[];
    about: any[];
    vacancies: any[];
    finance: any[];
    council: any[];
    councilDoc: any[];
    contacts: any[];
    partner: any[];
    cards: any[];
    productItems: any[];
    loading: boolean;
}

interface AppContextType {
    state: AppState;
    actions: {
        fetchMainData: () => Promise<void>;
        fetchAboutData: () => Promise<void>;
        fetchProductData: () => Promise<void>;
        fetchVacanciesData: () => Promise<void>;
        fetchNewsData: () => Promise<void>;
        fetchFinanceData: () => Promise<void>;
        fetchCouncilData: () => Promise<void>;
        fetchContactsData: () => Promise<void>;
        fetchCouncilDoc: () => Promise<void>;
        fetchPartnerData: () => Promise<void>;
        fetchCardsData: () => Promise<void>;
        fetchProductItemsData: () => Promise<void>;
        formatDate: (dateString: string) => string;
        switchLanguage: (lang: string) => void;
    };
}


export const AppContext = createContext<AppContextType | undefined>(undefined);


const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [state, setState] = useState<AppState>({
        product: [],
        banner: [],
        news: [],
        about: [],
        vacancies: [],
        finance: [],
        council: [],
        councilDoc: [],
        contacts: [],
        partner: [],
        cards: [],
        productItems: [],
        loading: false,
    });


    const setLoading = (loading: boolean) => {
        setState((prev) => ({ ...prev, loading }));
    };

    const fetchData = useCallback(async (fetchFn: () => Promise<any>, key: keyof AppState) => {
        setLoading(true);
        try {
            const data = await fetchFn();
            setState((prev) => ({ ...prev, [key]: data }));
        } catch (error) {
            console.error(`Failed to fetch ${key}:`, error);
        } finally {
            setLoading(false);
        }
    }, []);

    const actions = {
        fetchMainData: () => fetchData(Services.getMain, "banner"),
        fetchAboutData: () => fetchData(Services.getAbout, "about"),
        fetchProductData: () => fetchData(Services.getProduct, "product"),
        fetchVacanciesData: () => fetchData(Services.getVacancies, "vacancies"),
        fetchNewsData: () => fetchData(Services.getNews, "news"),
        fetchFinanceData: () => fetchData(Services.getFinance, "finance"),
        fetchCouncilData: () => fetchData(Services.getCouncil, "council"),
        fetchContactsData: () => fetchData(Services.getContacts, "contacts"),
        fetchCouncilDoc: () => fetchData(Services.getCouncilDocument, "councilDoc"),
        fetchPartnerData: () => fetchData(Services.getPartner, "partner"),
        fetchCardsData: () => fetchData(Services.getCards, "cards"),
        fetchProductItemsData: () => fetchData(Services.getProductItems, "productItems"),
        formatDate: (dateString: string) => {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat("ru", { day: "numeric", month: "long", year: "numeric" }).format(date) + "г.";
        },
        switchLanguage: (lang: string) => {
            i18n.changeLanguage(lang);
            const newPath = `/${lang}${window.location.pathname.slice(3)}`;
            window.history.pushState({}, "", newPath);
        },

    };


    useEffect(() => {
        actions.fetchProductData();
        actions.fetchNewsData();

        window.scrollTo(0, 0);

    }, []);

    return (
        <AppContext.Provider value={{ state, actions }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};