import { ReactComponent as SliderPrevArrow } from "../../../assets/icons/controllers/slider_controller_prev.svg";

interface SamplePrevArrowProps {
    classes?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    fillColor?: string;
    noBackground?: boolean;
}

export const SamplePrevArrow: React.FC<SamplePrevArrowProps> = ({
    classes = "",
    style = {},
    onClick,
    fillColor = '#FFFFFF',
    noBackground = false
}) => {
    return (
        <div
            className={classes}
            style={{
                ...style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: noBackground ? "transparent" : "#00000",
                zIndex: '100',
            }}
            onClick={onClick}
        >
            <SliderPrevArrow fill={fillColor} />
        </div>
    );
};
