import { BaseApiWrapper } from "../BaseApi";

const getAbout = () => BaseApiWrapper.get('/about_us');

const getContacts = () => BaseApiWrapper.get('/contacts');

const getCouncil = () => BaseApiWrapper.get('/council');

const getCouncilDocument = () => BaseApiWrapper.get('/council_doc')

const getFinance = () => BaseApiWrapper.get('/finance');

const getMain = () => BaseApiWrapper.get('/main');

const getNews = () => BaseApiWrapper.get('/news');

const getNewsDetail = (id: any) => BaseApiWrapper.get(`news/${id}`)

const getPartner = () => BaseApiWrapper.get('/partners');

const getProduct = () => BaseApiWrapper.get('/products');

const getProductById = (id: any) => BaseApiWrapper.get(`/products/${id}`)

const getVacancies = () => BaseApiWrapper.get('/vacancy');

const getVacanciesDetails = (id: any) => BaseApiWrapper.get(`/vacancy/${id}`);

const getProductItems = () =>  BaseApiWrapper.get(`product_items`);

const getCards = ()  => BaseApiWrapper.get('/cards');

const getProductItemsDetails = (id:any) => BaseApiWrapper.get(`product_items?productId=${id}`)

//post forms endpoint

const consultForm = (formData: any) => BaseApiWrapper.post('/consultation-forms', formData);

const financForm = (formData: any) => BaseApiWrapper.post('/financing-forms', formData);

const partnerForm = (formData: any) => BaseApiWrapper.post('/partner-forms', formData);

const vacancyForm = (formData: any) => BaseApiWrapper.post('/vacancy-forms', formData);



export const Services = {
    getAbout,
    getContacts,
    getVacancies,
    getVacanciesDetails,
    getCouncil,
    getCouncilDocument,
    getFinance,
    getMain,
    getNews,
    getNewsDetail,
    getPartner,
    getProduct,
    getProductById,
    financForm,
    vacancyForm,
    partnerForm,
    consultForm,
    getProductItems,
    getCards,
    getProductItemsDetails,
}