import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

interface Product {
  _id: string;
  title: string;
}

interface SelectSmallProps {
  product: Product[];
  label: string;
  onSelect: (product: { id: number | string; title: string }) => void;
}

export default function SelectSmall({ product, onSelect, label }: SelectSmallProps) {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = React.useState<number | string>(
    product.length > 0 ? product[0]._id : ''
  );
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (product.length > 0 && !selectedProduct) {
      setSelectedProduct(product[0]._id);
    }
  }, [product]);


  const handleSelect = (id: number | string) => {
    const selectedProduct2 = product.find((item) => String(item._id) === String(id));
    if (selectedProduct2) {
      setSelectedProduct(String(selectedProduct2._id));
      onSelect({ id: selectedProduct2._id, title: selectedProduct2.title });
    }
    setIsOpen(false);
  };


  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative w-full">
      <button
        onClick={toggleDropdown}
        className="w-full px-4 py-3 bg-[#F3F4F6] border rounded-lg shadow-sm text-gray-700 flex items-center justify-between dark:bg-dark-mode-bg dark:text-white"
      >
        <span>{product.find((item) => item._id === selectedProduct)?.title || "Выберите продукт"}</span>
        {isOpen ? <AiOutlineUp className="ml-2" /> : <AiOutlineDown className="ml-2" />}
      </button>

      {isOpen && (
        <ul className="dropdown-content menu p-2 shadow bg-base-100 rounded-box absolute top-full left-0 w-full z-10 dark:bg-dark-mode-bg dark:text-white">
          {product.length === 0 ? (
            <li>
              <span className="text-gray-500 dark:text-white">{t('Нет доступных продуктов')}</span>
            </li>
          ) : (
            product.map((item: Product) => (
              <li key={item._id}>
                <a
                  onClick={() => handleSelect(item._id)}
                  className="cursor-pointer hover:bg-gray-100 p-2 block dark:hover:bg-dark-mode-card-bg dark:text-white"
                >
                  {item.title}
                </a>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}
