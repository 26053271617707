/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

interface ITabButtonProps {
  label: string;
  tabIndex: number;
  activeTab: number;
  onClick: () => void;
}
const TabButton: FC<ITabButtonProps> = ({
  label,
  tabIndex,
  activeTab,
  onClick,
}) => {
  const isActive = activeTab === tabIndex;
  const activeClass = "text-dark-blue border-b-2  border-dark-blue font-bold dark:text-white dark:border-white";
  const inactiveClass = "text-[#6B7280] font-medium";

  return (
    <li className="-mb-px mr-2 last:mr-0 text-center">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className={`text-xs  uppercase px-2 py-3 block leading-normal text-nowrap ${isActive ? activeClass : inactiveClass
          }`}
      >
        {label}
      </a>
    </li>
  );
};

export default TabButton;
