import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ROOT_PATHS } from "../routes/paths";
import { useTranslation } from "react-i18next";
import ModalUi from "../components/UI/ModalUi";
import { FinancingForm } from "../components/forms/FinancingForm";
import Drawer from "./Drawer";
import { icons } from "../assets/icons";
import { Icon } from "../components/UI/Icon";
import { LanguageSwitcher } from "../components";
import ThemeToggle from "../components/ThemeToggle";
import { locale } from "../helpers/language";
import i18n from "../i18/i18n";
import { useAppContext } from "../contexts/BaseConfigContext";


const MainHeader = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false)
  const { lang } = useParams();
  const { actions: { switchLanguage } } = useAppContext();


  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };


    if (lang) {
      i18n.changeLanguage(lang);
      switchLanguage(lang);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lang])




  const headerLinksTop = [
    {
      label: t("header.financialReport"),
      path: ROOT_PATHS.financialProfitability,
    },
    { label: t("header.profitNorm"), path: ROOT_PATHS.profitNorm },
    { label: t("header.vacancies"), path: ROOT_PATHS.vacancies },
  ];

  const headerLinksBottom = [
    { label: t("header.products"), path: ROOT_PATHS.products },
    { label: t("header.partners"), path: ROOT_PATHS.partner },
    { label: t("header.shariaCouncil"), path: ROOT_PATHS.shariaCouncil },
    { label: t("header.news"), path: ROOT_PATHS.news },
    { label: t("header.aboutUs"), path: ROOT_PATHS.about },
  ];

  const headerFilialy = [
    { label: t("header.branches"), path: ROOT_PATHS.filialy }
  ]


  return (
    <div>
      <div className="z-[4000]">
        <ModalUi title="Заявка на консультацию" isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FinancingForm open={openModal} setOpen={setOpenModal} />
        </ModalUi>
      </div>

      <div className="fixed top-0 left-0 w-full shadow-md bg-white z-[5000] dark:bg-dark-mode-bg  ">
        <div className="max-w-7xl px-4 mx-auto">
          <div className="grid grid-cols-[1fr_10fr] py-2 ">
            <div className="flex gap-3 py-2 w-[100px]">
              <Link
                to={`https://www.bakai.kg/${lang}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon image={icons.BakaiLogo} alt="BakaiLogo" />
              </Link>
              <Link to={"/"}>
                <Icon image={icons.LogoIcon} alt="LogoIcon" />
              </Link>

            </div>

            <div className="hidden lg:block">
              <div className="flex  items-center py-2">
                <ul className="flex gap-7 mr-auto">
                  {headerLinksTop.map((link, index) => (
                    <li
                      key={index}
                      className="text-sm font-[500] text-gray-500 dark:text-gray-400"
                    >
                      {link.path ? (
                        <Link to={link.path}>{link.label}</Link>
                      ) : (
                        link.label
                      )}
                    </li>
                  ))}
                </ul>

                <div className="flex items-center gap-4 ml-auto">
                  <LanguageSwitcher />
                  <div className="block  max-md:hidden">
                    <ThemeToggle />

                  </div>
                  {headerFilialy.map((link, index) => (
                    <Link key={index} to={link.path} className="font-[500] dark:text-white">
                      {link.label}
                    </Link>
                  ))}

                </div>
              </div>

              <hr className="mb-2 h-0.5 border-t-0 bg-header-border-gray dark:bg-gray-700" />
              <div className="grid grid-cols-[5fr_1fr] items-center  ">
                <ul className="flex gap-7 ">
                  {headerLinksBottom.map((link, index) => (
                    <li
                      key={index}
                      className="text-lg font-medium text-dark-blue dark:text-white"
                    >
                      <Link to={link.path}>{link.label}</Link>
                    </li>
                  ))}
                </ul>
                <div className="flex justify-end items-center py-2">
                  <button
                    onClick={() => setOpenModal(true)}
                    className={`text-[#01554F] text-[18px] font-[700] text-center rounded-[32px] hover:bg-dark-green hover:text-white shadow-lg dark:bg-dark-green dark:hover:bg-white dark:hover:text-dark-green dark:text-white 
                      transition-all duration-200 ease-in-out 
                      ${isScrolled ? "py-[10px] px-[18px]" : "py-[16px] px-[24px]"}`}
                  >
                    {t("header.financingButton")}
                  </button>
                </div>
              </div>
            </div>

            <div className="block  py-2 ml-auto lg:hidden">
              <div className="flex gap-4 items-center">
                <ThemeToggle />
                <Drawer />
              </div>

            </div>

          </div>
        </div>
      </div>
    </div >
  );
};

export default MainHeader;
