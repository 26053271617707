import { Outlet } from "react-router-dom";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const MainLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      <MainHeader />
      <div className="flex-grow bg-white  dark:bg-dark-mode-bg">
        <Outlet />
      </div>
      <MainFooter />
    </div>
  );
};

export default MainLayout;
