import { ReactNode, MouseEventHandler } from "react";

interface CustomButtonProps {
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean; 
}

export default function CustomButton({
    children,
    type = "button",
    className = "",
    disabled = false, 
    onClick,
}: CustomButtonProps) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`py-[16px] px-[24px] text-[18px] font-[700] rounded-[32px] ${className} ${disabled ? "opacity-50 cursor-not-allowed" : ""}`} // Add disabled styles
            disabled={disabled}
        >
            {children}
        </button>
    );
}
