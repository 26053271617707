import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "./UI/Breadcrumb";
import { useAppContext } from "../contexts/BaseConfigContext";
import { Cards } from "./UI/Cards/Cards";
import { getImage } from "../helpers/image";
import { carta } from "../constants/cards";
import { Icon } from "./UI/Icon";
import { icons } from "../assets/icons";
import ModalUi from "./UI/ModalUi";

const IslamCarta: React.FC = () => {
    const { t } = useTranslation();
    const { state: { cards }, actions: { fetchCardsData }
    } = useAppContext();
    const [openModal, setOpenModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        fetchCardsData();
    }, []);

    return (
        <div className="mt-10 mb-14">
            <div className="max-w-7xl px-4 mx-auto">
                <div>
                    <Breadcrumb
                        breadcrum2={t("ProductsPage.IslamicProducts")}
                        link2="/products"
                        breadcrum3={"Исламские банковские карты"}
                    />
                    <div className="flex flex-col gap-6 my-8">
                        <h1 className="text-dark-blue text-4xl font-bold py-3 dark:text-white">
                            Исламские банковские карты
                        </h1>
                        {cards.length > 0 &&
                            cards?.filter(item => item.isActive).map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <Cards
                                            key={item?._id}
                                            title={item?.title}
                                            shortInfo={item?.short_info}
                                            image={getImage(item?.imageLocation)}
                                            text={item?.text}
                                        >
                                            <div className="grid gap-3 mt-4 md:grid-cols-2">
                                                <button
                                                    onClick={() => setOpenModal(true)}
                                                    className="bg-[#01554F] text-white text-center block py-4 text-[18px] font-[700] px-4 rounded-[32px] "
                                                >
                                                    {t("ProductsPage.openInApp")}
                                                </button>
                                                <ModalUi isOpen={openModal} onClose={() => setOpenModal(false)} title="Как открыть карту?">
                                                    <h1 className="text-center text-[18px] font-[600] dark:text-white">Оформите карту в мобильном приложении BakAi</h1>

                                                    <div className="flex gap-[25px] items-center max-md:flex-col">
                                                        <div className="flex-1 max-w-lg max-md:order-2 overflow-hidden">
                                                            <div
                                                                className="mb-4 flex justify-center p-5 bg-gray-200 w-[500px] rounded-lg max-md:w-full ">
                                                                {carta.length > 0 && (
                                                                    <img
                                                                        src={carta[activeIndex].image}
                                                                        alt={carta[activeIndex].title}
                                                                        className="max-w-md max-h-72 rounded-lg max-md:max-w-xs "
                                                                    />
                                                                )}
                                                            </div>
                                                            <a
                                                                href="https://bakai.app/download"
                                                                className=" p-[12px] flex justify-around items-center  rounded-lg text-white"
                                                                style={{
                                                                    background: "linear-gradient(90deg, #EE275C 10%, #1428A0 100%)",
                                                                    backgroundSize: '200% 200%',
                                                                    animation: 'gradientAnimation 3s ease infinite',
                                                                }}
                                                            >
                                                                <div className="flex w-full gap-[20px] justify-center max-md:w-[30%]">
                                                                    <Icon image={icons.AppStore} alt="appStore" className="max-md:hidden" />
                                                                    <h3 className="text-lg font-[600] hidden max-md:block">Скачать</h3>
                                                                    <Icon image={icons.BakaiDownlad} alt="appStore" />
                                                                    <Icon image={icons.PlayMarket} alt="appStore" className="max-md:hidden" />
                                                                </div>
                                                            </a>


                                                        </div>




                                                        <div className="flex-1 flex flex-col gap-4 max-md:order-1">
                                                            {carta.map((card, index) => (
                                                                <div key={index}>
                                                                    <div className="flex gap-2 items-center">
                                                                        <button
                                                                            onClick={() => setActiveIndex(index)}
                                                                            className={`px-4 py-2 border rounded-full dark:text-white ${activeIndex === index ? "bg-[#01554F] text-white" : ""
                                                                                } border-gray-300`}
                                                                        >
                                                                            {card.nums}
                                                                        </button>
                                                                        <p className="text-[20px] font-[600] max-md:text-[16px] dark:text-white">
                                                                            {card.title}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </ModalUi>





                                            </div>
                                        </Cards>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IslamCarta;
