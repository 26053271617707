import React from "react";
import { parseText } from "../helpers/parser";
import { renderContentBlock } from "../helpers/editorText";

const IslamicFinanceCenter = ({ partner }: any) => {



  return (
    <div className="pl-4">
      {partner.map((item: any, index: number) => {
        const { text } = item;

        const { error, content, message } = parseText(text, index);


        return (
          <div key={index} className="mb-6 dark:text-white">
            {content && content.blocks.map(renderContentBlock)}
          </div>
        );
      })}
    </div>
  );
};

export default IslamicFinanceCenter;
