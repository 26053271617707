import { useEffect, useState } from "react";
import CustomButton from "../UI/CustomButtom";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
import { useSnackbar } from "../../contexts/sneckbar";
import Input from "../UI/Input";
import { Services } from "../../api/services";
import ReCAPTCHA from "react-google-recaptcha";
import {captchaKey} from "./FinancingForm";

interface CustomModalProps {
  onClose: () => void;
  partner?: boolean;
  products?: boolean;
  title?: string;
  openModal: any;
}

export const FormProducts: React.FC<CustomModalProps> = ({ onClose, partner, products = false, title, openModal }) => {
  const [formData, setFormData] = useState({ fio: "", phone: "", email: "", name: title ,captcha:"" });
  const [errors, setErrors] = useState({ fio: "", phone: "", email: "", checkbox: "",captcha:""  });
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false)
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [captchaResponse, setCaptchaResponse] = useState(null);




  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "phone" && !value.startsWith("+996")) {
      value = "+996";
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };


  const validateForm = () => {
    const newErrors = { fio: "", phone: "", email: "", checkbox: "" ,captcha:"" };

    if (!formData.fio.trim()) {
      newErrors.fio = t("forms.fioError");
    }
    if (!formData.phone.trim()) {
      newErrors.phone = t("forms.phoneError");
    } else if (!/^\+996\s\d{3}\-\d{3}-\d{3}$/.test(formData.phone)) {
      newErrors.phone = t("forms.phoneTest");
    }
    if (!formData.email.trim()) {
      newErrors.email = t("forms.emailError");
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = t("forms.emailTest");
    }
    if (!isChecked) {
      newErrors.checkbox = "Вы должны принять условия политики конфиденциальности."
    }


    if (!captchaResponse) {
      newErrors.captcha = "Пожалуйста, подтвердите, что вы не робот.";
    }

    setErrors(newErrors);
    return !newErrors.fio && !newErrors.phone && !newErrors.email && !newErrors.checkbox;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;


    setLoading(true);

    try {
      await Services.consultForm(formData);
      showSnackbar(t("forms.snackbarSucces"), "success");
      onClose();
      setFormData({ fio: "", phone: "", email: "", name: "",captcha:""  });
    } catch (error) {
      console.error("Form submission error:", error);
      showSnackbar(t("forms.snackbarError"), "error");
      onClose();

    } finally {
      setLoading(false);
    }
  };


  const onChangeCaptcha = (v: any) => {
    setCaptchaResponse(v);

    if (v) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        captcha: "",
      }));
    }
  };


  return (
    <div className=" grid gap-4">
      <Input
        error={errors.fio}
        placeholder="Введите имя"
        value={formData.fio}
        onChange={handleInputChange}
        type="text"
        name="fio"
      />

      <ReactInputMask
        mask="+996 999-999-999"
        maskChar={null}
        value={formData.phone || "+996"}
        onChange={handleInputChange}
        onFocus={() => {
          if (!formData.phone) {
            setFormData((prevState) => ({ ...prevState, phone: "+996" }));
          }
        }}
      >
        {() => (

          <Input
            error={errors.phone}
            name="phone"
            placeholder="Номер телефона"
            aria-describedby="phone-error-text"
          />
        )}
      </ReactInputMask>

      <Input
        error={errors.email}
        placeholder="Введите почта"
        value={formData.email}
        onChange={handleInputChange}
        type="email"
        name="email"
      />

      <label className="flex flex-col items-start">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">
            Согласен с условиями политики конфиденциальности.
          </span>
        </div>
        {errors.checkbox && (
          <p className="text-red-500 text-sm mt-1">{errors.checkbox}</p>
        )}
      </label>


      <ReCAPTCHA
          sitekey={`${captchaKey}`}
          onChange={onChangeCaptcha}
      />
      {errors.captcha && (
          <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>
      )}

      <CustomButton
        onClick={handleSubmit}
        className="w-full py-3 bg-[#01554F] text-white rounded-full"
        disabled={loading || !captchaResponse}
      >
        {loading ? t("forms.btnMobailePanding") : t("forms.btnModal")}
      </CustomButton>


      <hr />

      <div className="flex flex-col items-start gap-2">
        <p className="text-black font-[500] dark:text-white">
          611 <span className="text-[#6B7280] font-[400]">для звонков по КР</span>
        </p>
        <p className="text-black font-[500] dark:text-white">
          +996 554 006 011 <span className="text-[#6B7280] font-[400]">Whats’App</span>
        </p>
      </div>




    </div>
  );
};
