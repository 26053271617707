import HeroSection, { HeroTypes } from "./UI/HeroSection";
// import {parseText, renderText} from "../helpers/parser";
import { getImage } from "../helpers/image";
import Loader from "./UI/Loader";
import ModalUi from "./UI/ModalUi";
import { useReducer, useRef, useState } from "react";
import { FormProducts } from "./forms/FormProducts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { SampleNextArrow } from "./UI/SliderControllers/NextArrow";
import { SamplePrevArrow } from "./UI/SliderControllers/PrevArrow";

interface ProductData {
    product: any;
    loading: any;
}
const MainPageSwiper = (props: ProductData) => {
    const { product, loading } = props;
    const sliderRef = useRef<Slider>(null);
    const [modalTitle, setModalTitle] = useState("");



    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState<string | null>(null);
    const isActive = Array.isArray(product)
        ? product.filter((item: any) => item.isActive)
        : [];


    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 10000,

        appendDots: (dots: any) => (
            <div style={{ position: "absolute", bottom: "2px", textAlign: "center", width: "100%" }}>
                <ul style={{ margin: "0", padding: "0", display: "inline-flex", gap: "40px" }} >
                    {dots}
                </ul>
            </div>
        ),
        customPaging: (i: any) => (
            <div
                style={{
                    width: "60px",
                    height: "1.7px",
                    background: "#B0BEC5",
                    borderRadius: "2px",
                    transition: "background 0.3s ease",
                }}
            />
        ),
        nextArrow: <SampleNextArrow
            onClick={() => sliderRef.current?.slickNext()}
            fillColor="white"
            classes="custom-arrow-banner custom-arrow-next-banner bg-gray-400 w-[40px] h-[40px] rounded-[10px] cursor-pointer "
        />,
        prevArrow: <SamplePrevArrow
            onClick={() => sliderRef.current?.slickPrev()}
            fillColor="white"
            classes="custom-arrow-banner custom-arrow-prev-banner  bg-gray-400 w-[40px] h-[40px] rounded-[10px] cursor-pointer "
        />,
    };

    return (
        <div>
            {isActive.length > 0 ? (
                <div >
                    <div className="slider-container ">
                        <Slider ref={sliderRef} {...settings}>
                            {isActive.map((product: any, index: number) => {
                                const { title, imageLocation, text } = product?.banners_id || {};
                                return (
                                    <HeroSection
                                        key={index}
                                        image={getImage(imageLocation)}
                                        title={title || ""}
                                        subtitle={text}
                                        button="Оставить заявку"
                                        type={HeroTypes.Partners}
                                        setOpenModal={() => {
                                            setModalTitle(title || "");  // Устанавливаем title перед открытием
                                            setOpenModal(true);
                                        }}
                                        setModalType={setModalType}

                                    />
                                );
                            })}
                        </Slider>
                    </div>

                </div>
            ) : (
                loading && <Loader height="59vh" />
            )}

            {/* Modal is rendered outside Swiper */}
            {openModal && (
                <ModalUi
                    title={
                        "Заявка на продукты"
                    }
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                >

                    <FormProducts title={modalTitle} onClose={() => setOpenModal(false)} openModal={openModal} />
                </ModalUi>
            )}
        </div>
    );
};

export default MainPageSwiper;
