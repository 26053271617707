import React, { useState } from "react";
import CustomMap from "./UI/CustomMap";
import { MarkerCluster } from "./MapComponent";
import { FilBranch, Fililay } from "../types/filialy";

const FilialyContent = (props: Fililay) => {
  const [hoveredLocationId, setHoveredLocationId] = useState<string | null>(
    null
  );

  const handleMouseEnter = (id: string) => setHoveredLocationId(id);
  const handleMouseLeave = () => setHoveredLocationId(null);

  const hoveredLocation = props.branches?.find(
    (location: FilBranch) => location._id === hoveredLocationId
  );


  return (
    <div className="grid grid-cols-1 md:grid-cols-[1fr_3fr] gap-4">
      <div className="block">
        <div className="carousel carousel-vertical  h-[37rem] flex flex-col  max-md:h-[10rem]">
          {props.branches ? (
            props.branches.map((location: FilBranch) => (
              <div
                key={location._id && location._id}
                onMouseEnter={() =>
                  handleMouseEnter(location._id && location._id)
                }
                onMouseLeave={handleMouseLeave}
                className={` cursor-pointer ${hoveredLocationId === location._id
                  ? "bg-gray-100 transition-all dark:bg-dark-mode-card-bg"
                  : ""
                  }`}
              >
                <div className="border-b py-3 px-2 border-gray-600"> <p className="text-[18px] font-[500] dark:text-white">Адрес</p> <span className="text-gray-500 text-[16px]">{location.address}</span> </div>
              </div>
            ))
          ) : null}
        </div>
      </div>
      <div>
        <CustomMap>
          <MarkerCluster
            locations={
              hoveredLocation
                ? [hoveredLocation]
                : props.branches || []
            }
          />
        </CustomMap>
      </div>
    </div>
  );
};

export default FilialyContent;
