import { FC } from "react";

interface IFinanceTermSelectorProps {
  terms: number[];
  month: number;
  setMonth: (val: number) => void;
}

const FinanceTermSelector: FC<IFinanceTermSelectorProps> = ({
  terms,
  month,
  setMonth,
}: IFinanceTermSelectorProps) => (
  <div className="flex py-4 gap-2 justify-center">
    {terms.map((term: number) => (
      <div
        key={term}
        onClick={() => setMonth(term)}
        className={`py-6 px-6 font-semibold ${term === month
          ? "bg-dark-green cursor-pointer text-white"
          : "bg-[#F3F4F6] dark:bg-dark-mode-bg dark:text-white"
          } text-lg cursor-pointer leading-[0.1] grid place-content-center rounded-xl`}
      >
        {term}
      </div>
    ))}
  </div>
);

export default FinanceTermSelector;
