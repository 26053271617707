import { FC } from "react";
import CustomButton from "./UI/CustomButtom";
import FinanceTermSelector from "./FinanceTermSelector";

interface ICalculatorCardProps {
  title: string;
  financeAmount: string;
  minAmount: number;
  maxAmount: number;
  terms: number[];
  buttonText: string;
  amount: number;
  handleAmount: (newAmount: number) => void;
  month: number;
  setMonth: (val: number) => void;
  calculate: () => void;
}

const CalculatorCard: FC<ICalculatorCardProps> = ({
  title,
  financeAmount,
  minAmount,
  maxAmount,
  terms,
  buttonText,
  amount,
  handleAmount,
  month,
  setMonth,
  calculate,
}) => {
  return (
    <div className="min-w-[200px]  flex flex-col justify-around items-center bg-white shadow-md rounded-2xl p-2 pb-6 dark:bg-dark-mode-card-bg">
      <h2 className="text-3xl font-semibold text-dark-blue text-center dark:text-white">
        {title}
      </h2>
      <div className=" flex gap-6 flex-col">
        <div className="font-semibold text-lg text-[#6B7280] text-center">
          "Сумма финансирования"
        </div>
        <div className="bg-[#F3F4F6] w-full  flex items-center py-4 px-8 text-3xl rounded-2xl dark:bg-dark-mode-bg">
          <input
            type="text"
            value={amount}
            onChange={(e: any) => handleAmount(e.target.value)}
            min={minAmount}
            max={maxAmount}
            className=" bg-transparent text-dark-green font-semibold text-3xl w-[200px] border-none focus:outline-none"
          />
          <span className="text-dark-green font-semibold"> KGS</span>
        </div>
        <div className="text-[#6B7280] font-semibold text-lg text-center">
          от {minAmount} до {maxAmount}
        </div>
        <div className="">
          <p className="font-semibold text-lg text-[#6B7280] text-center">
            Срок финансирования
          </p>
          <p className="bg-[#F3F4F6] text-3xl text-dark-green font-bold py-4 rounded-md px-10 text-center dark:bg-dark-mode-bg">
            {month} месяцев
          </p>
        </div>
        <FinanceTermSelector terms={terms} month={month} setMonth={setMonth} />
        <CustomButton
          onClick={calculate}
          className="bg-dark-green py-1 text-white"
        >
          {buttonText}
        </CustomButton>
      </div>
    </div>
  );
};

export default CalculatorCard;
