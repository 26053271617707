import React, { FC } from "react";

interface IContactItem {
    Icon: FC;
    text: string;
    bgColor?: "white" | "black";
    href?: string;
    textColor?: string
}

const ContactItem: FC<IContactItem> = ({ Icon, text, bgColor = "white", href }) => {
    const textColor = bgColor === "black" ? "text-white" : "text-black";

    const content = (
        <div className="flex items-center gap-2">
            <Icon />
            <span className={`${textColor} font-semibold text-lg  sm:text-xl dark:${textColor} `}>{text}</span>
        </div>
    );

    return href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
    ) : (
        content
    );
};

export default ContactItem;
