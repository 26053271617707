import React from "react";
import { Link } from "react-router-dom";
import { getImage } from "../../helpers/image";

function TopProductCard({
    slide,
    active,

}: {
    slide: any;
    active: boolean;
}) {
    return (
        <>
            <div className={`slide ${active ? "activeSlide" : "slide"}`}>
                <Link to={`products/${slide?.product_id}`}>

                    <img className="rounded-[20px]" src={getImage(slide.imageLocation)} alt='image' />
                    <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t via-transparent to-transparent">
                        <p className="text-[26px] font-[600] text-white text-center absolute bottom-0 w-full p-4 max-md:text-[16px]">
                            {slide.title}
                        </p>
                    </div>
                </Link>

            </div>
        </>

    );
}

export default TopProductCard;
