interface InputInterface {
  type?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  value?: string;
  onChange?: (e: any) => void;
  label?: string;
  error?: string;
}

export default function Input({
  type,
  placeholder = "Enter text",
  id = "input",
  name = "",
  value,
  onChange,
  label,
  error,
}: InputInterface) {


  return (
    <div >
      <label className="text-[18px] font-[500] text-gray-700 dark:text-white">{label}</label>
      <input
        name={name}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full px-4 py-3 bg-[#F3F4F6] dark:bg-dark-mode-bg border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent text-gray-700 dark:text-white placeholder-gray-400  hover:shadow-md transition duration-200 ease-in-out ${error ? ' border-red-600' : 'border-gray-300'}`}
      />
      {error && <div className="text-red-900">{error}</div>}
    </div>
  );
}
