import React, { FC } from "react";
import Murabaha from "../assets/images/murabaha.webp"

interface IPaymentDetailsCardProps {
  imageSrc: string;
  monthlyPayment: string;
  totalPayment: string;
  term: string;
  payment: any;
}

const PaymentDetailsCard: FC<IPaymentDetailsCardProps> = ({
  imageSrc,
  monthlyPayment,
  totalPayment,
  term,
  payment,
}) => (
  <div className="w-full h-full bg-dark-green rounded-2xl flex items-center justify-center gap-5">
    <div className="max-sm:flex flex-col-reverse gap-5">
      <img
        className=""
        src={Murabaha}
        alt=""
      />
      <div className="grid justify-center gap-4">
        <div className="text-[#F3F4F6] font-bold text-lg text-center py-5">
          Ежемесячный платеж
        </div>
        <div className="text-white font-semibold text-4xl text-center">
          {payment.paymentMonth} KGS
        </div>
        <div className="text-[#F3F4F6] font-bold max-w-60 text-center py-3">
          Общая выплата{" "}
          <span className="text-white font-semibold">
            {payment.paymentAll} KGS
          </span>{" "}
          на срок <span className="text-white font-semibold">{term}</span>
        </div>
      </div>
    </div>
  </div>
);

export default PaymentDetailsCard;
