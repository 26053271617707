import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SampleNextArrow } from "./UI/SliderControllers/NextArrow";
import { SamplePrevArrow } from "./UI/SliderControllers/PrevArrow";
import Slider from "react-slick";
import TopProductCard from "./UI/TopProductCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





interface ProductData {
    product: any;
    loading: any;
}
const IslamicFinanceProducts = (props: ProductData) => {
    const { t } = useTranslation()
    const sliderRef = useRef<Slider>(null);
    const [imageIndex, setImageIndex] = useState(0)

    const [selectedType, setSelectedType] = useState("client");

    const filteredProducts = props.product.filter(
        (product: any) => product.product_type === selectedType
    );




    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        speed: 500,
        centerPadding: '20px',
        nextArrow: <SampleNextArrow
            onClick={() => sliderRef.current?.slickNext()}
            fillColor="white"
            classes="custom-arrow custom-arrow-next bg-gray-400 w-[50px] h-[50px] rounded-[10px] cursor-pointer dark:bg-dark-mode-card-bg"
        />,
        prevArrow: <SamplePrevArrow
            onClick={() => sliderRef.current?.slickPrev()}
            fillColor="white"
            classes="custom-arrow custom-arrow-prev bg-gray-400 w-[50px] h-[50px] rounded-[10px] cursor-pointer dark:bg-dark-mode-card-bg"
        />,
        beforeChange: (current: any, next: any) => setImageIndex(next),
        afterChange: (current: any) => setImageIndex(current),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '150px',
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '60px',
                },
            },
        ],
    };




    return (
        <div >
            <h1 className="text-dark-blue text-4xl font-bold text-center m-12 dark:text-white max-md:text-3xl">
                {t("islamicFinanceProducts.title")}
            </h1>

            <div className="flex justify-center  hidden-scrollbar overflow-x-auto items-center text-[18px] font-[500] mb-8 gap-4 bg-gray-100 py-2 px-6 w-fit m-auto rounded-[40px] dark:bg-dark-mode-card-bg max-md:text-[16px]  ">
                <button
                    className={` px-4 py-2 rounded-[30px] ${selectedType === "client" ? "bg-dark-green text-white transition-all" : " text-[#9EA3AD]"}`}
                    onClick={() => setSelectedType("client")}
                >
                    {t("footer.forClients")}
                </button>
                <button
                    className={`px-4 py-2 rounded-[30px] ${selectedType === "business" ? "bg-dark-green text-white transition-all" : " text-[#9EA3AD]"}`}
                    onClick={() => setSelectedType("business")}
                >
                    {t("footer.forBusiness")}
                </button>
            </div>


            <div className="">

                <Slider ref={sliderRef} {...settings}>
                    {filteredProducts.map((item: any, index: any) => (
                        <TopProductCard key={index} slide={item} active={index === imageIndex} />
                    ))}

                </Slider>

            </div>

        </div>
    );
};

export default IslamicFinanceProducts;
