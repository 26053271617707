import { icons } from "../assets/icons";
import { ROOT_PATHS } from "../routes/paths";

export const SOCIAL_LINKS = [
    {
        href: "https://www.instagram.com/ifcenter.kg",
        alt: "Instagram",
        icon: icons.Instagram,
    },
    {
        href: "https://t.me/ifcenter.kg",
        alt: "Telegram",
        icon: icons.Telegram,
    },
    {
        href: "https://www.facebook.com/ifcenter.kg",
        alt: "Facebook",
        icon: icons.Facebook,
    },
    {
        href: "https://twitter.com/ifcenter.kg",
        alt: "Twitter",
        icon: icons.Twitter,
    },
];


