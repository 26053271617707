import React from "react";
import Accordion from "./UI/accardion/Accordion";

interface AccordionItem {
  question: string;
  answers: string;
}

const ProductDetailFAQTab: React.FC<{
  accordionItems: AccordionItem[];
}> = ({ accordionItems }) => {
  return (
    <div>
      <div className="grid gap-4">
        {accordionItems.map((item, index) => (
          <Accordion
            key={index}
            title={item.question}
            description={item.answers}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductDetailFAQTab;
