import React, { useEffect, useState } from "react";
import PDF from "../assets/icons/Frame.svg";
import Loader from "./UI/Loader";
import { useAppContext } from "../contexts/BaseConfigContext";
import ModalUi from "./UI/ModalUi";


const ProductDetailDocumentTab = () => {
  const { state: { councilDoc, loading }, actions: { formatDate, fetchCouncilDoc } } = useAppContext();
  const [currentPdf, setCurrentPdf] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchCouncilDoc()
  }, [])

  const openModal = (pdfUrl: any) => {
    setCurrentPdf(pdfUrl);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCurrentPdf("");
  };
  return (
    <div>
      <div className="">
        {councilDoc.map((item, index) =>
          item.documents.map((document: any, docIndex: any) => (
            <div
              key={index}
              className="flex rounded-lg bg-[#eeecec] shadow-md cursor-pointer mb-4 items-center space-x-2 dark:bg-dark-mode-card-bg"
              onClick={() => openModal(document.fileLocation)}
            >
              <div className="text-black flex items-center gap-2   p-2 rounded">
                <img src={PDF} className="w-[30px] h-[30px]" alt="pdf" />
                <span className="text-sm dark:text-white">
                  {document.title} - {formatDate(document.date)}
                </span>
              </div>
            </div>
          ))
        )}
        <ModalUi isOpen={isModalVisible} onClose={closeModal} title="Документ">
          <div className=" w-[90vw] sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[40vw] h-[90vh] flex items-center justify-center">
            {currentPdf && (
              <iframe
                title="pdf-viewer"
                className="h-[100%] w-[100%]"
                src={currentPdf}
                allowFullScreen
              ></iframe>
            )}
          </div>
        </ModalUi>
      </div>
    </div>
  );
};

export default ProductDetailDocumentTab;
