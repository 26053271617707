import { Link, } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parseText, renderText } from "../helpers/parser";
import { useAppContext } from "../contexts/BaseConfigContext";
import { Cards } from "./UI/Cards/Cards";
import { getImage } from "../helpers/image";
import CustomLink from "./UI/CustomLink";
import { renderContentBlock } from "../helpers/editorText";
import { locale } from "../helpers/language";

const NewsHome = () => {
    const { t } = useTranslation();

    const { state: { news }, actions: { formatDate, fetchNewsData } } = useAppContext();




    return (
        <div className="py-8 max-w-7xl px-4 mx-auto flex flex-col gap-8">
            <h1 className="text-4xl font-bold  dark:text-white max-md:text-[30px]">
                {t("NewsHome.title")}
            </h1>
            <div className="flex flex-col gap-8 w-full max-sm:gap-6">
                {news && news.filter(item => item.isActive).slice(0, 3).map((item: any, index: number) => {
                    const { content } = parseText(item.text)

                    return (

                        <Cards
                            key={index}
                            title={item.title.split(" ").length > 10
                                ? `${item.title.split(" ").slice(0, 8).join(" ")}...`
                                : item.title}
                            text={content && content.blocks.map(renderContentBlock)}
                            image={item.gallery[0].imageLocation ? getImage(item.gallery[0].imageLocation) : "https://t4.ftcdn.net/jpg/05/17/53/57/360_F_517535712_q7f9QC9X6TQxWi6xYZZbMmw5cnLMr279.jpg"}
                            date={formatDate(item.date)}>
                            <CustomLink
                                link={`news/${item.id}`}
                                className="block bg-gray-100 border-black-100 border text-center w-[150px] hover:bg-gray-200 transition-all dark:bg-dark-mode-bg dark:text-white"
                            >
                                Читать
                            </CustomLink>
                        </Cards>
                    )
                })}
            </div>
            <Link
                to={`/${locale}/news`}
                className="py-4 px-6 mx-auto rounded-full bg-[#01554F] text-lg font-bold text-white"
            >
                {t("NewsHome.allNews")}
            </Link>
        </div>
    );
};

export default NewsHome;
