import { MapContainer, TileLayer } from "react-leaflet";
import React, { ReactNode } from "react";

interface CustomMapProps {
  children?: ReactNode;
}

export default function CustomMap({ children }: CustomMapProps) {
    const tileUrlWebp = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    const tileUrlPng = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  return (
    <div>
      <MapContainer
        center={[42.878, 74.606]}
        zoom={13}
        className="w-full h-[37rem] rounded-[20px]"
      >
        <TileLayer
          url={tileUrlWebp}
          errorTileUrl={tileUrlPng}
          attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
        />
        {React.Children.map(children, (child) => {
          return child;
        })}
      </MapContainer>
    </div>
  );
}
