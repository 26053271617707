import React, { FC, useState } from "react";
import PDF from "../../../assets/icons/Frame.svg";
import { useAppContext } from "../../../contexts/BaseConfigContext";
import { parseText } from "../../../helpers/parser";
import { renderContentBlock } from "../../../helpers/editorText";
import ModalUi from "../ModalUi";

function isObject(value: unknown) {
  return typeof value === 'object' && value !== null;
}

interface IAccordionProps {
  title: string;
  description: string | string[];
  document?: { fileLocation: string; title: string; date: string }[] | string;
}

const Accordion: FC<IAccordionProps> = ({ title, description, document }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { actions: { formatDate } } = useAppContext();
  const [currentPdf, setCurrentPdf] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);


  const openModal = (pdfUrl: string) => {
    setCurrentPdf(pdfUrl);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCurrentPdf("");
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const { error, message, content } = parseText(description);

  const contentRender = error ? isObject(message) ? '' : message : content?.blocks.map(renderContentBlock)

  return (
    <div className="w-full">
      <div className="rounded-md shadow-md">
        <button
          className="w-full text-start p-5 flex items-center justify-between bg-white text-dark-blue font-medium dark:bg-dark-mode-card-bg"
          onClick={toggleAccordion}
        >
          <span className="dark:text-white">{title}</span>
          <div className="bg-[#f6f2f2ea] rounded-full p-1 dark:bg-dark-mode-bg">
            <svg
              className={`w-6 h-6 transition-transform duration-300 transform ${isOpen ? "rotate-180" : "rotate-0"
                } text-black dark:text-white`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </button>

        <div
          className={`overflow-hidden transition-all duration-300 ${isOpen ? "max-h-screen ease-in" : "max-h-0 ease-out"
            }`}
        >
          <div className="px-4 py-3 flex flex-col gap-3 bg-white dark:bg-dark-mode-card-bg dark:text-white">
            {Array.isArray(document) &&
              document.map((doc, index) => (
                <div
                  key={index}
                  className="flex rounded-lg bg-[#eeecec] cursor-pointer shadow-md mb-4 items-center space-x-2"
                  onClick={() => openModal(doc.fileLocation)}
                >
                  <div className="text-black flex items-center gap-2 p-2 rounded">
                    <img src={PDF} className="w-[30px] h-[30px]" alt="pdf" />
                    <span className="text-sm dark:text-white">
                      {doc.title}  {doc.date && '- ' + formatDate(doc.date)}
                    </span>
                  </div>
                </div>
              ))}
            <p >
              {contentRender}
            </p>
          </div>
        </div>
      </div>
      <ModalUi isOpen={isModalVisible} onClose={closeModal} title="Документ">
        <div className=" w-[90vw] sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[40vw] h-[90vh] flex items-center justify-center">
          {currentPdf && (
            <iframe
              title="pdf-viewer"
              className="h-[100%] w-[100%]"
              src={currentPdf}
              allowFullScreen
            ></iframe>
          )}
        </div>
      </ModalUi>
    </div>
  );
};

export default Accordion;
