import React, { ReactNode, useEffect } from "react";
import { Icon } from "./Icon";
import { icons } from "../../assets/icons";

interface CustomModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: ReactNode;
    className?: string
}

const ModalUi: React.FC<CustomModalProps> = ({ isOpen, onClose, title, children, className }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <div
            id="static-modal"
            data-modal-backdrop="static"
            tabIndex={-1}
            aria-hidden={!isOpen}
            className={`${isOpen ? "flex" : "hidden"
                } fixed top-0 left-0 right-0 modalIndex  h-full justify-center items-center `}
        >
            <div
                className="fixed inset-0  bg-black bg-opacity-50"
                onClick={onClose}
                aria-hidden="true"
            ></div>

            <div className="relative p-4  max-h-full z-50">
                <div className={`relative ${className ? className : 'bg-white dark:bg-dark-mode-card-bg'}  rounded-lg shadow `}>
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 ">
                        <h3 className="text-xl font-semibold text-gray-900 max-md:text-lg dark:text-white ">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-dark-mode-bg rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                            onClick={onClose}
                        >
                            <Icon image={icons.MenuClose} alt={"close"} size={40} />
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4 hidden-scrollbar max-h-[80vh]">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalUi;
