import { CardProps, ShortInfo } from "../../../types/cards";

export const Cards = (props: CardProps) => {
    return (
        <div className="flex gap-5 bg-white rounded-[15px] shadow-md p-4 lg:flex-row lg:flex-nowrap flex-wrap dark:bg-dark-mode-card-bg">
            {props.image && <div className=" bg-[#F9FAFB]  rounded-lg h-[280px] lg:w-[400px] w-full">
                <img
                    src={props.image}
                    alt={props.title}
                    className="block w-full h-full object-cover rounded-lg"
                />
            </div>}
            <div className="flex flex-col flex-1">
                <h1 className="text-[28px] text-[#111928] font-bold dark:text-white">{props.title}</h1>
                <p className="text-[14px] font-[400] text-[#6B7280]">{props.date}</p>
                <div className="h-[110px] overflow-hidden">
                    <span className="text-[#6B7280] text-[18px] font-[400] mt-5 mb-2">{props.text}</span>

                </div>
                {props.shortInfo && props.shortInfo?.map((item: ShortInfo, index: number) => (
                    <div className="grid grid-cols-2 " key={index}>
                        <div>
                            <p className="text-[#6B7280] font-[500] dark:text-white">{item.title}</p>
                        </div>
                        <div className="">
                            <p className=" font-medium text-dark-blue dark:text-gray-300">{item.text}</p>
                        </div>
                    </div>
                ))}
                <div className="mt-auto ">
                    {props.children}
                </div>
            </div>
        </div>
    );
};
