import React, { ReactNode, useState } from "react";
import CustomButton from "./CustomButtom";
import Breadcrumb from "./Breadcrumb";
import { icons } from "../../assets/icons";
import Banner from "../../assets/images/Banner.webp"

export enum HeroTypes {
    Partners = "partners",
    Products = "products",
}




const HeroSection = ({
    title,
    subtitle,
    button,
    image,
    type,
    breadcrum2,
    breadcrum3,
    setOpenModal,
    setModalType,
}: {
    title: string;
    subtitle: string;
    button: ReactNode;
    image: string;
    type?: string;
    breadcrum2?: string;
    breadcrum3?: string;
    setOpenModal?: (open: boolean) => void;
    setModalType?: (type: string) => void;
}) => {
    return (
        <div
            style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
            }}
            className="h-[70vh] max-md:h-[100vh]"

        >
            <div>
                <div className="max-w-[78rem] m-auto py-[4%] px-8 max-md:py-10">
                    {breadcrum2 &&

                        <Breadcrumb breadcrum2={breadcrum2} color={'#ffff'} icon={icons.Breadcrumb2} breadcrum3={breadcrum3} link2={breadcrum3 ? '/products' : undefined} />


                    }


                    <div className="flex flex-col gap-10  md:flex-row  justify-between">
                        <div className="flex flex-col gap-8 max-w-xl">
                            <div className="flex flex-col gap-4 ">
                                <h1 className="text-[40px] md:text-[60px] font-bold text-white  ">{title}</h1>
                                <p className="text-[18px] md:text-[24px] font-medium text-white">
                                    {subtitle}
                                </p>
                            </div>
                            <div>
                                <CustomButton
                                    className="bg-white text-black px-4 py-2 "
                                    onClick={() => {
                                        setOpenModal && setOpenModal(true);
                                    }}
                                >
                                    {button}
                                </CustomButton>
                            </div>
                        </div>
                        <div >
                            <img
                                src={image}
                                alt="product"
                                className="object-cover w-full  "
                            />
                        </div>


                    </div>


                </div>
            </div>


        </div >
    );
};

export default HeroSection;
