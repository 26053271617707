import { useEffect } from "react";
import { router } from "../routes";

const ScrollToTop = () => {
    useEffect(() => {
        const unsubscribe = router.subscribe(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        });

        return () => unsubscribe();
    }, []);

    return null;
};

export default ScrollToTop;
