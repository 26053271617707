import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from "../contexts/BaseConfigContext";
import { parseText } from "../helpers/parser";
import { renderContentBlock } from "../helpers/editorText";
import { getImage } from "../helpers/image";

export default function IslamFinCenterHome() {
    const { t } = useTranslation();
    const { state: { banner }, actions: { fetchMainData } } = useAppContext();
    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    const [activeContent, setActiveContent] = useState<any>(null);
    const navContainerRef = useRef<HTMLDivElement>(null);

    const handleToggle = (index: number, content: string) => {
        const container = navContainerRef.current;

        if (container) {
            const targetButton = container.children[index] as HTMLElement;

            if (targetButton) {
                const offset = targetButton.offsetLeft - container.offsetLeft;
                const containerWidth = container.offsetWidth;
                const targetWidth = targetButton.offsetWidth;

                container.scrollTo({
                    left: offset - (containerWidth - targetWidth) / 2,
                    behavior: "smooth",
                });
            }
        }

        setActiveIndex(activeIndex === index ? null : index);
        setActiveContent(content);
    };


    useEffect(() => {
        fetchMainData();
    }, []);

    useEffect(() => {
        if (banner && banner[0] && banner[0]?.nav_banners_id?.length > 0) {
            const firstItem = banner[0].nav_banners_id[0];
            const { content } = parseText(firstItem.text, 0);
            setActiveContent(content);
        }
    }, [banner]);

    const navBanners = banner && banner[0] && banner[0]?.nav_banners_id || [];

    return (
        <div className="bg-dark-paper min-h-[588px] mt-20 mb-20 dark:bg-dark-mode-card-bg max-md:h-full">
            <div className="py-[2rem]">
                <h1 className="text-[40px] font-[700] text-white text-center max-md:text-[28px]">
                    {t("IslamFinCenterHome.title")}
                </h1>
                <div className="mt-[2rem] text-white grid grid-cols-1  md:grid-cols-2 max-w-7xl mx-auto">
                    <div className="max-md:px-3 mb-9">
                        <div
                            ref={navContainerRef}
                            className="flex hidden-scrollbar gap-6 py-3 overflow-x-auto"
                        >
                            {navBanners.map((item: any, index: number) => {
                                const { content } = parseText(item.text, index);
                                const isActive = activeIndex === index;
                                return (
                                    <button
                                        key={index}
                                        className={`whitespace-nowrap px-4 py-2 text-[16px] font-medium transition-all duration-300 rounded ${isActive
                                            ? "border-b-2 border-[#01554F] text-white bg-[#01554F]"
                                            : "border-b-2 border-transparent text-gray-400 hover:text-white hover:bg-gray-500"
                                            }`}
                                        onClick={() => handleToggle(index, content)}
                                    >
                                        {item.title}
                                    </button>
                                );
                            })}
                        </div>

                        {activeContent && (
                            <div className="flex flex-col gap-4 text-[16px] py-3 text-gray-200">
                                {activeContent.blocks.map(renderContentBlock)}
                            </div>
                        )}
                    </div>

                    <div className="w-full ml-auto flex  justify-end max-md:justify-center">
                        {navBanners.map((item: any, index: number) => (
                            <img
                                key={index}
                                src={getImage(item.imageLocation)}
                                alt="mous"
                                className={` transition-all duration-300 ${activeIndex === index ? 'opacity-100' : 'opacity-0 hidden'}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
