import BakaiLogo from "./BakaiLogoIcon.svg"
import LogoIcon from "./LogoIcon.svg"
import WhatsAppIcon from "./whatsappicon.svg"
import PhoneIcon from "./PhoneIcon.svg"
import Burger from "./BurgerIcon.svg"
import MenuClose from "./MenuClose.svg"
import Instagram from "./instagram.svg"
import Telegram from "./Telegram.svg"
import Twitter from "./TwitterIcon.svg"
import Facebook from "./FacebookIcon.svg"
import Location from "./LocationIcon.svg"
import Gmail from "./GmailIcon.svg"
import BreadcrumbIcon from "./BreadcrumbSeparateIcon.svg"
import LanguageRu from "./ru.svg"
import LanguageKg from "./kg.svg"
import IfcIcon from "./ifcIcon.svg"
import Breadcrumb2 from "./breadcrumb2.svg"
import DarkModeSun from "../icons/controllers/dark_mode_controller_sun.svg"
import DarkModeMoon from "../icons/controllers/dark_mode_controller_moon.svg"
import PlayMarket from "../icons/Playmarket.svg"
import BakaiDownlad from "../icons/BakaiDowndoad.svg"
import AppStore from "../icons/AppStore.svg"



export const icons = {
    Location,
    BakaiLogo,
    LogoIcon,
    WhatsAppIcon,
    PhoneIcon,
    Burger,
    MenuClose,
    Instagram,
    Telegram,
    Twitter,
    Facebook,
    Gmail,
    BreadcrumbIcon,
    LanguageRu,
    LanguageKg,
    IfcIcon, Breadcrumb2,
    DarkModeMoon,
    DarkModeSun,
    BakaiDownlad,
    PlayMarket,
    AppStore

}