import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from "../i18/i18n";

export const LanguageRedirect = () => {
    const navigate = useNavigate();
    const { lang } = useParams();

    useEffect(() => {
        if (!lang || !["ru", "ky"].includes(lang)) {
            navigate("/ru");
        } else {
            i18n.changeLanguage(lang);
        }
    }, [lang, navigate]);

    return null;
};
