import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { locale } from "../helpers/language";

interface IFooterSection {
    title: string;
    items: { links: { href: string; text: string }[] }[];
}

const FooterSection: FC<IFooterSection> = ({ title, items }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <div className="border-b border-gray-700">
            <div
                className="flex justify-between items-center text-2xl text-white font-bold py-5 cursor-pointer max-md:text-[24px] sm:cursor-auto"
                onClick={() => setIsExpanded((prev) => !prev)}
            >
                {title}
                <div className="bg-[#252525ea] rounded-full max-md:p-1">
                    <svg
                        className={`w-6 h-6 transition-transform duration-300 transform ${isExpanded ? "rotate-180 ease-out" : "rotate-0 ease-in"
                            } sm:hidden`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </div>
            </div>

            <div
                className={`transition-all duration-300 ease-in-out ${isExpanded ? "max-h-screen" : "max-h-0 overflow-hidden sm:max-h-none"}`}
            >
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
                    {items.map(({ links }, index: number) => (
                        <div key={index}>
                            <ul className="space-y-2">
                                {links.map(({ href, text }) => {
                                    const cleanedHref = href.startsWith("/products/") ? href : `/products/${href}`;
                                    return (
                                        <li key={text} className="text-lg text-gray-200">
                                            <Link to={`/${locale}${cleanedHref}`} className="hover:underline">
                                                {text}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FooterSection;
