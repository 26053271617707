const TabContent = ({
  children,
  isVisible,
}: {
  children: React.ReactNode;
  isVisible: boolean;
}) => {
  return <div className={isVisible ? "block" : "hidden"}>{children}</div>;
};

export default TabContent;
