import { createBrowserRouter, Navigate } from "react-router-dom";
import { ROOT_PATHS } from "./paths";
import MainLayout from "../layout/MainLayout";
import { lazy, Suspense } from "react";
import { LanguageRedirect } from "../components/LanguageRedirect";

const MainPage = lazy(() => import("../pages/MainPage"));
const AboutPage = lazy(() => import("../pages/AboutPage"));
const FilialyPage = lazy(() => import("../pages/FilialyPage"));
const NewsPage = lazy(() => import("../pages/NewsPage"));
const PartnerPage = lazy(() => import("../pages/PartnerPage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));
const ProductDetailPage = lazy(() => import("../pages/ProductDetailPage"));
const ProfitNormPage = lazy(() => import("../pages/ProfitNormPage"));
const ShariaCouncilPage = lazy(() => import("../pages/ShariaCouncilPage"));
const VacanciesDetailPage = lazy(() => import("../pages/VacanciesDetailPage"));
const VacanciesPage = lazy(() => import("../pages/VacanciesPage"));
const NewsDetailPage = lazy(() => import("../pages/NewsDetails"));
const FinancialProfitability = lazy(() => import("../pages/FinancialProfitability"));
const NotFoundPage = lazy(() => import("../pages/NotFound"));

export const router = createBrowserRouter([
   {
      path: "/",
      element: <LanguageRedirect />
   },
   {
      path: "/:lang",
      element: <MainLayout />,
      children: [
         { index: true, element: <Suspense fallback=""><MainPage /></Suspense> },
         { path: ROOT_PATHS.about, element: <Suspense fallback=""><AboutPage /></Suspense> },
         { path: ROOT_PATHS.filialy, element: <Suspense fallback=""><FilialyPage /></Suspense> },
         { path: ROOT_PATHS.financialProfitability, element: <Suspense fallback=""><FinancialProfitability /></Suspense> },
         { path: ROOT_PATHS.news, element: <Suspense fallback=""><NewsPage /></Suspense> },
         { path: ROOT_PATHS.newsDetail, element: <Suspense fallback=""><NewsDetailPage /></Suspense> },
         { path: ROOT_PATHS.partner, element: <Suspense fallback=""><PartnerPage /></Suspense> },
         { path: ROOT_PATHS.productDetails, element: <Suspense fallback=""><ProductDetailPage /></Suspense> },
         { path: ROOT_PATHS.products, element: <Suspense fallback=""><ProductsPage /></Suspense> },
         { path: ROOT_PATHS.shariaCouncil, element: <Suspense fallback=""><ShariaCouncilPage /></Suspense> },
         { path: ROOT_PATHS.vacancies, element: <Suspense fallback=""><VacanciesPage /></Suspense> },
         { path: ROOT_PATHS.vacanciesDetail, element: <Suspense fallback=""><VacanciesDetailPage /></Suspense> },
         { path: ROOT_PATHS.profitNorm, element: <Suspense fallback=""><ProfitNormPage /></Suspense> },
         { path: "*", element: <Suspense fallback=""><NotFoundPage /></Suspense> }
      ]
   }
]);
