interface LoaderProps {
    height: string;
}

export default function Loader({ height }: LoaderProps) {
    return (
        <div className="w-full">
            <div className="flex justify-center w-full">
                <div
                    className={`w-full bg-gray-300 animate-pulse`}
                    style={{ height }}
                ></div>
            </div>
        </div>
    );
}
